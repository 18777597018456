/* eslint-disable consistent-return */
import React from 'react';
import UserIcon from 'Common/Components/Icons/UserIcon';
import SecurityIcon from 'Common/Components/Icons/SecurityIcon';
import { ROUTES } from '../../../Constants/routes';

export function getSideBarItems() {
  return [
    {
      name: 'Profile',
      link: ROUTES.ACCOUNT_PROFILE,
      icon: <UserIcon />,
      subMenu: [],
    },
    {
      name: 'Security',
      link: ROUTES.ACCOUNT_SECURITY,
      icon: <SecurityIcon />,
      subMenu: [],
    },
  ];
}

export const PAGE_CONFIG = {
  title: 'Account Settings',
  tagline: 'View/edit your profile and security settings',
  metaTitle: 'Account Settings',
  metaDescription: 'View/edit your profile and security settings',
};

export const getFormatedbackUpCodes = (backUpCodes: string[]) => {
  if (!backUpCodes?.length) return;

  const chunks = [];
  for (let i = 0; i < backUpCodes.length; i += 4) {
    chunks.push(backUpCodes.slice(i, i + 4));
  }
  return chunks;
};

export const getbackUpCodesAText = (backUpCodes: string[]) => backUpCodes.join(', ');
