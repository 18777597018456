import {
  APP, DEV, LOCAL, STAGING,
} from '../Constants';

const getFavicon = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case LOCAL:
      return '/favicon_localhost.ico';
    case DEV:
      return '/favicon_dev.ico';
    case STAGING:
      return '/favicon_staging.ico';
    case APP:
      return '/favicon_app.ico';
    default:
      return '/favicon_app.ico';
  }
};

export default getFavicon;
