import React, { ButtonHTMLAttributes, MouseEventHandler } from 'react';
import styles from './styles.module.scss';
import { CustomComponentProps } from '../../Types/component';
import Loader from '../Loader';

export interface ButtonProps extends CustomComponentProps {
  loaderColor?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled?: boolean;
  loading?: boolean;
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'text'
    | 'default'
    | 'danger'
    | 'transparent'
    | 'ghost';
}

function Button({
  id,
  children,
  className,
  onClick,
  type,
  variant,
  disabled,
  loading,
  loaderColor,
}: ButtonProps) {
  return (
    <button
      id={id}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`btn btn-${variant} ${styles.woBtn} ${className || ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
      {loading ? <Loader loaderColor={loaderColor} className={styles.loader} show /> : null}
    </button>
  );
}

Button.defaultProps = {
  onClick: () => {},
  type: 'button',
  disabled: false,
  loading: false,
  variant: 'primary',
  loaderColor: 'currentColor',
};

export default Button;
