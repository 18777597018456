import * as React from 'react';

interface AddRoundedIconProps {
  size?: string | number;
  color?: string;
  isOutlined?: boolean;
}
function AddRoundedIcon({ size, color, isOutlined }: AddRoundedIconProps) {
  if (isOutlined) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M7.33398 7.83594V5.16927H8.66732V7.83594H11.334V9.16927H8.66732V11.8359H7.33398V9.16927H4.66732V7.83594H7.33398ZM8.00065 15.1693C4.31875 15.1693 1.33398 12.1845 1.33398 8.5026C1.33398 4.8207 4.31875 1.83594 8.00065 1.83594C11.6825 1.83594 14.6673 4.8207 14.6673 8.5026C14.6673 12.1845 11.6825 15.1693 8.00065 15.1693ZM8.00065 13.8359C10.9462 13.8359 13.334 11.4481 13.334 8.5026C13.334 5.55708 10.9462 3.16927 8.00065 3.16927C5.05513 3.16927 2.66732 5.55708 2.66732 8.5026C2.66732 11.4481 5.05513 13.8359 8.00065 13.8359Z"
          fill={color || '#3766E8'}
        />
      </svg>
    );
  }
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={color} />
      <g clipPath="url(#clip0_50308_4812)">
        <path
          d="M17 12.7143H12.7143V17H11.2857V12.7143H7V11.2857H11.2857V7H12.7143V11.2857H17V12.7143Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_50308_4812">
          <rect width="10" height="10" fill="white" transform="translate(7 7)" />
        </clipPath>
      </defs>
    </svg>
  );
}

AddRoundedIcon.defaultProps = {
  size: '16',
  color: '#194DDE',
  isOutlined: false,
};

export default AddRoundedIcon;
