import React, { lazy } from 'react';
import LazyRoute from 'Common/Components/LazyRoute';

const AccountSecurity = lazy(() => import('../../Components/AccountSecurityComponent'));

function AccountSecurityContainer() {
  return <LazyRoute lazy={AccountSecurity} />;
}

export default AccountSecurityContainer;
