export enum RequestStatus {
  SUCCESS = 200,
  CREATED = 201,
  NO_CONTENT = 203,
  UN_AUTH = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
  UNPROCESSABLE_ENTITY = 422,
}

export interface ApiRequest {}
