import {
  ACCESS_DRIVETHRU,
  ACCESS_PERSON_SUITE,
  ACCESS_POS_ANALYTICS,
  ACCESS_THRUPUT_LEADERBOARD,
  WOCAM_ACCESS_LIVEVIEW,
  WOCAM_VIEW_AND_TAG_RECORDINGS,
} from './accesslistConstants';

const TASK_TYPES = {
  COUNT_BASED: 'count-based',
  TIME_BASED: 'time-based',
  OCCUPANCY_BASED: 'occupancy-based',
  PROCESS_BASED: 'process-based',
  WOPIPE: 'wopipe',
  JOURNEY_BASED: 'journey-based',
  HEATMAP_BASED: 'heatmap-based',
  PROCESS_COMPLIANCE: 'process-compliance',
};

export default TASK_TYPES;

export const allOptions = {
  companies: { label: 'All Companies', value: '' },
  locations: { label: 'All Locations', value: '' },
  tasks: { label: 'All Tasks', value: '' },
  cameras: { label: 'All Cameras', value: '' },
  taskTypes: { label: 'All Task Types', value: '' },
  status: { label: 'All Status', value: '' },
  select: { label: 'Select...', value: '' },
};

export const LOCAL = 'local';
export const DEV = 'dev';
export const STAGING = 'staging';
export const APP = 'app';

export const DEBOUNCE_INPUT_TIME = 1000;

export const allModulesForCompanies = [
  WOCAM_ACCESS_LIVEVIEW,
  WOCAM_VIEW_AND_TAG_RECORDINGS,
  ACCESS_DRIVETHRU,
  // ACCESS_ANALYTICS,
  ACCESS_PERSON_SUITE,
  ACCESS_POS_ANALYTICS,
  ACCESS_THRUPUT_LEADERBOARD,
];

export enum TagTypes {
  TAG_WARNING = 'warning',
  TAG_SUCCESS = 'success',
  TAG_ORANGE = 'orange',
  TAG_YELLOW = 'yellow',
  TAG_MUTED = 'muted',
  TAG_PURPLE = 'purple',
  TAG_BLOCK = 'block',
  TAG_FAILURE = 'failure',
  TAG_DEFAULT = 'default',
}

export const today = new Date();
export const startOfDay = new Date(today.setHours(0, 0, 0, 0));
export const endOfDay = new Date(today.setHours(23, 59, 59, 999));
export const sevenDaysAgo = new Date(today);
sevenDaysAgo.setDate(today.getDate() - 6);

export const tableRowsPerPageSizeRegular = [10, 25, 50];
export const tableRowsPerPageSizeLarge = [10, 25, 50, 75, 100, 125, 150];
