export const validateEmpty = (value: string | number) => {
  if (typeof value === 'string') {
    return value.trim() === '';
  }

  if (typeof value === 'number') {
    return Number.isNaN(value);
  }

  return false;
};

export const validateHexCode = (value: string) => {
  const colorHexRegex = /^#?([0-9a-fA-F]{6})$/gi;
  return !colorHexRegex.test(value);
};

export const validateName = (inputNameStr: string) => {
  if (inputNameStr) {
    const name = /^[a-zA-Z][a-zA-Z0-9 ]*(?:-[a-zA-Z0-9 ]+)*$/;
    return name.test(inputNameStr);
  }
  return true;
};

export const validateEmail = (email: string) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !emailRegex.test(email);
};

export const validateUrl = (url: string) => {
  const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gm;
  return !urlRegex.test(url);
};

export const validate = (url: string) => {
  const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gm;
  return !urlRegex.test(url);
};

export const validatePath = (url: string) => {
  const pathRegex = /\.(plan|engine|onnx|hef|xml|bin)$/;
  return !pathRegex.test(url);
};

export const validateSubDomain = (url: string) => {
  const subDomainRegex = /^([a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
  return !subDomainRegex.test(url);
};

export const validateRequiredInputText =
  (text?: string | number) => ((text !== '' && text !== undefined && text !== null) ? !validateEmpty(text) : false);

export const validatePassword = (password: string) => {
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
  return !passwordRegex.test(password);
};

export const validateVersion = (version: string) => {
  const versionRegex = /^(?:[0-9]+\.){0,2}[0-9]+$/gm;
  return !versionRegex.test(version);
};

export function validateRequiredUrlText(text: string) {
  return validateRequiredInputText(text) && !validateUrl(text);
}

export function validateEmailRequired(value: string) {
  return validateRequiredInputText(value) && !validateEmail(value);
}

export function validatePasswordRequired(value: string) {
  return validateRequiredInputText(value) && !validatePassword(value);
}
