import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import PageNotFound from 'Common/Pages/PageNotFound';
import LazyRoute from 'Common/Components/LazyRoute';
import { ROUTES } from '../../Constants/routes';
import DeviceManagementLoadingSkeleton from './Components/Devices/DeviceManagementLoadingSkeleton';

const LazyDevices = React.lazy(() => import('./Containers/Devices'));
const LazyModelManagement = React.lazy(() => import('./Containers/ModelManagement'));

function Tools() {
  return (
    <Routes>
      <Route
        path={ROUTES.DEVICE_MANAGEMENT}
        element={<LazyRoute fallback={<DeviceManagementLoadingSkeleton />} lazy={LazyDevices} />}
      />
      <Route path={ROUTES.MODELS_MODULE} element={<LazyRoute lazy={LazyModelManagement} />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Tools;
