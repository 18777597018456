import Modal, {
  ModalContent, ModalFooter, ModalTitle,
} from 'Common/Components/Modal';
import React, {
  ChangeEvent, FormEvent, useCallback, useEffect, useState,
} from 'react';
import Select, { SelectOption } from 'Common/Components/Form/Select';
import TextInput from 'Common/Components/Form/TextInput';
import Textarea from 'Common/Components/Form/Textarea';
import FileUpload from 'Common/Components/Form/FileUpload';
import Button from 'Common/Components/Button';
import { validateRequiredInputText } from 'Common/Helpers/validation';
import Logger from 'Common/Helpers/logger';
import useMakeRequest from 'Common/Hooks/useMakeRequest';
import { NOTIFICATION_TYPES, addNotification } from 'Common/Components/Notification';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import styles from './raiseATicket.module.scss';
import {
  ContactOptionsStringMap,
  PriorityOptionsStringMap,
  contactOptions,
  getErrorMessage,
  intialRequestFormData,
  priorityOptions,
} from './helper';
import { HandlerEvent, IRaiseTicketForm } from './Types';
import raiseATicketUseCase from './UseCases/raiseATicketUseCase';
import { AppState } from '../../../Store';
import getCompaniesListUseCase from '../../../Modules/Company/UseCases/getCompaniesListUseCase';

interface RaiseATicketProps {
  show: boolean;
  onClose: () => void;
}

function RaiseATicket({ show, onClose }: RaiseATicketProps) {
  const [formData, setFormData] = useState<IRaiseTicketForm>(intialRequestFormData);
  const [errorFields, setErrorFields] = useState<Record<string, string>>({});
  const [partnerCompanies, setPartnerCompanies] = useState<SelectOption[]>([]);
  const { details, contactFor, subject, priority, selectedCompanyId, selectedCompanyName, media } =
    formData;

  const selectAuth = (state: AppState) => state.auth;

  const selectUser = createSelector([selectAuth], (auth) => ({ user: auth?.user }));

  const { user } = useSelector(selectUser);

  const fetchPartnerCompanies = useCallback(async () => {
    try {
      const response = await getCompaniesListUseCase({
        limit: 1000,
        page: 1,
      });
      const companyOptions = response?.companies?.map((comp) => ({
        label: comp?.company,
        value: comp?._id,
      }));
      companyOptions && setPartnerCompanies(companyOptions);
    } catch (error) {
      Logger('Error fetching companies', error);
    }
  }, []);

  const handleInputChange =
    (
      { key, validate }: HandlerEvent,
    ) => (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const { value } = e.target;

      setErrorFields((prev) => ({
        ...prev,
        [key]: validate(value) ? '' : getErrorMessage(key),
      }));

      setFormData((prev: IRaiseTicketForm) => ({ ...prev, [key]: value }));
    };

  const saveTicketData = useCallback(async () => {
    try {
      if (user) {
        const response = await raiseATicketUseCase(
          {
            selectedCompanyId,
            selectedCompanyName,
            subject,
            details,
            contactFor,
            priority,
            userId: user.id,
            username: user.username,
            role: user.role,
          },
          media || '',
        );
        if (response) {
          addNotification('Successfully raised a support ticket', NOTIFICATION_TYPES.SUCCESS);
          setFormData(intialRequestFormData);
          onClose();
        }
      } else {
        addNotification('Unable fetch user details', NOTIFICATION_TYPES.SUCCESS);
      }
    } catch (ex) {
      Logger('Failed to raise a support ticket', ex);
    }
  }, [
    selectedCompanyId,
    selectedCompanyName,
    subject,
    details,
    contactFor,
    priority,
    onClose,
    media,
    user,
  ]);

  const { loading, fetchData: saveData } = useMakeRequest({
    fetcher: saveTicketData,
    fetchDataOnMount: false,
  });

  const validateAndSaveTicketData = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      const errors: Record<string, string> = {
        subject: !validateRequiredInputText(formData.subject) ? getErrorMessage('subject') : '',
        details: !validateRequiredInputText(formData.details) ? getErrorMessage('details') : '',
        contactFor: !formData.contactFor ? getErrorMessage('contactFor') : '',
        priority: !formData.priority ? getErrorMessage('priority') : '',
        company: !formData.selectedCompanyName ? getErrorMessage('company') : '',
      };

      if (Object.keys(errors).some((key) => errors[key])) {
        setErrorFields(errors);
        return;
      }

      saveData();
    },
    [formData, saveData],
  );

  useEffect(() => {
    if (partnerCompanies.length === 0) {
      fetchPartnerCompanies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      className={styles.raiseATicketModal}
      overlayClassName={styles.overlayStyle}
      containerClassName={styles.modalContainer}
      show={show}
      onClose={onClose}
      type="form"
      onSubmit={validateAndSaveTicketData}
      renderInBody={false}
    >
      <ModalTitle
        title="Raise a support request"
        description="Contact Wobot's Support Team for any issues or queries"
        onClose={onClose}
        className={styles.modalTitle}
      />
      <ModalContent className={styles.modalContent}>
        <div className={styles.selectFieldGroup}>
          <Select
            className={styles.selectField}
            placeholder="Select"
            label="Request type "
            name="contactFor"
            options={contactOptions}
            value={
              contactFor
                ? {
                  label: contactFor,
                  value: contactFor,
                }
                : undefined
            }
            handleChange={(newValue) => {
              if (newValue) {
                setFormData((prev: IRaiseTicketForm) => ({
                  ...prev,
                  contactFor: ContactOptionsStringMap[newValue.value],
                }));
              }
            }}
            error={!contactFor}
            message={errorFields.contactFor}
          />
          <Select
            className={styles.selectField}
            label="Priority "
            placeholder="Select"
            name="priority"
            options={priorityOptions}
            value={
              priority
                ? {
                  label: PriorityOptionsStringMap[priority],
                  value: priority,
                }
                : undefined
            }
            handleChange={(newValue) => {
              if (newValue) {
                setFormData((prev: IRaiseTicketForm) => ({
                  ...prev,
                  priority: newValue.value,
                }));
              }
            }}
            error={!priority}
            message={errorFields.priority}
          />
        </div>
        <Select
          placeholder="Select"
          label="Company "
          name="company"
          options={partnerCompanies}
          handleChange={(newValue) => {
            if (newValue) {
              setFormData((prev: IRaiseTicketForm) => ({
                ...prev,
                selectedCompanyId: newValue.value,
                selectedCompanyName: newValue.label,
              }));
            }
          }}
          error={!selectedCompanyName || !selectedCompanyId}
          message={errorFields.company}
        />
        <TextInput
          label="Subject "
          placeholder="Enter subject"
          value={subject.charAt(0).toUpperCase() + subject.slice(1)}
          maxLength={140}
          type="text"
          name="subject"
          onChange={handleInputChange({
            key: 'subject',
            validate: validateRequiredInputText,
          })}
          error={!!errorFields.subject}
          message={errorFields.subject}
          highlightErrorInput={false}
        />
        <Textarea
          label="Details "
          placeholder="Provide details so we can help you better"
          value={details.charAt(0).toUpperCase() + details.slice(1)}
          name="details"
          textareaClassName={styles.textareaStyles}
          onChange={handleInputChange({
            key: 'details',
            validate: validateRequiredInputText,
          })}
          error={!!errorFields.details}
          message={errorFields.details}
          highlightErrorTextarea={false}
        />
        <FileUpload
          label="Attach a file"
          onUpload={(file) => setFormData({ ...formData, media: file })}
          accept={{
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/gif': ['.gif'],
            'application/vnd.ms-excel': ['.xslv'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
          }}
        />
      </ModalContent>
      <ModalFooter content="end">
        <Button type="button" variant="text" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button type="submit" className="btn-primary" loading={loading} disabled={loading}>
          Submit request
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default RaiseATicket;
