import React, { JSX } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Breadcrumbs, { BreadcrumbsProps } from 'Common/Components/Breadcrumb';
import getFavicon from 'Common/Helpers/getFavicon';
import styles from './page-layout.module.scss';

interface PageLayoutProps {
  breadcrumbs?: BreadcrumbsProps['links'];
  title?: string;
  tagline?: string;
  pageMetaTitle: string;
  pageMetaDescription?: string;
  actionButtons?: JSX.Element;
  children?: JSX.Element | string;
}

function PageLayout({
  breadcrumbs = undefined,
  title,
  tagline,
  actionButtons = undefined,
  pageMetaTitle,
  pageMetaDescription,
  children,
}: PageLayoutProps) {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{pageMetaTitle || title}</title>
        <meta name="description" content={pageMetaDescription || tagline} />
        <link id="favicon" rel="icon" href={getFavicon()} />
      </Helmet>
      <section className={styles.pageLayoutContainer}>
        <div className={styles.pageLayoutDataWrapper}>
          <div className={styles.titleContainer}>
            <div>
              {breadcrumbs ? (
                <Breadcrumbs className={styles.breadcrumbs} links={breadcrumbs} />
              ) : null}
              <h4 className={styles.title}>{title}</h4>
            </div>
            <div className={styles.actions}>{actionButtons}</div>
          </div>
        </div>
        {tagline ? <p className={styles.tagline}>{tagline}</p> : null}
      </section>
      {children}
    </HelmetProvider>
  );
}

PageLayout.defaultProps = {
  breadcrumbs: null,
  actionButtons: null,
  tagline: '',
  pageMetaDescription: '',
  children: null,
  title: '',
};

export default PageLayout;
