import request, { METHODS } from 'Common/Requests/request';
import { RequestStatus } from 'Common/Types/request';
import { NOTIFICATION_TYPES, addNotification } from 'Common/Components/Notification';
import { API_ENDPOINT_EXTENSION, BASE_API_URL } from '../../Config';
import { deleteAuthTokenCookie, getAuthToken } from './appCookie';
import { hideLoaderUseCase, showLoaderUseCase } from '../Components/GlobalLoader/UseCases';
import getCommonHeaders from './getCommonHeaders';
import { ROUTES } from '../Constants/routes';

export const createQueryParams = (params?: Record<string, any>): string => {
  if (!params || typeof params !== 'object') {
    return '';
  }

  const query = Object.entries(params).reduce((acc, [key, value]) => {
    if (value === '' || value === undefined || value === null) {
      return acc;
    }

    const prefix = acc ? '&' : '';
    return `${acc}${prefix}${key}=${encodeURIComponent(value)}`;
  }, '');

  return query ? `?${query}` : '';
};

export interface ApiError extends Error {
  status: RequestStatus;
  statusCode: RequestStatus;
  message: string;
  details: {
    loginAttempts?: number;
    err?: string;
  };
}

export interface ApiRequest {
  url: string;
  method?: METHODS;
  body?: Record<string, any>;
  queryParams?: Record<string, any>;
  headers?: RequestInit['headers'];
  apiEndpointExtension?: string;
}
export interface ApiResponse<T> {
  data: T;
  status: RequestStatus;
  statusCode: RequestStatus;
  message?: string;
  details?: {
    token: string;
  };
  code?: string;
}

export interface ApiCallConfig {
  showLoader?: boolean;
  loaderLabel?: string;
  notifySuccess?: boolean;
  successMessage?: string;
  notifyErrorMessage?: boolean;
  errorMessage?: string;
  showWarnMessage?: boolean;
}

async function apiCall<T>(
  {
    url,
    method,
    body,
    queryParams,
    headers,
    apiEndpointExtension = API_ENDPOINT_EXTENSION,
  }: ApiRequest,
  apiConfig?: ApiCallConfig,
): Promise<T> {
  const { showLoader, loaderLabel, notifySuccess, successMessage } = apiConfig || {};

  if (showLoader) {
    showLoaderUseCase({ label: loaderLabel });
  }

  try {
    const token = getAuthToken();

    const response = await request<ApiResponse<T>>({
      url: `${BASE_API_URL}${apiEndpointExtension}${url}${createQueryParams(queryParams)}`,
      method,
      body: JSON.stringify(body),
      headers: {
        ...getCommonHeaders(),
        ...(token ? { token } : {}),
        ...headers,
      },
    });

    if (
      [response.status, response.statusCode].includes(RequestStatus.SUCCESS) ||
      [response.status, response.statusCode].includes(RequestStatus.CREATED)
    ) {
      const message = response.message || successMessage;

      if (notifySuccess && message) {
        addNotification(message);
      }
      if (!response.data) {
        response.data = {
          ...response.data,
          details: response.details,
          code: response.code,
        } as T;
      }
      return response.data;
    }

    throw response;
  } catch (ex) {
    const error = ex as ApiError;
    let { notifyErrorMessage, errorMessage, showWarnMessage } = apiConfig || {};

    errorMessage = errorMessage || error.message || 'Something went wrong';
    const warnMessage = error?.details && error?.details?.loginAttempts;
    showWarnMessage = !!warnMessage && warnMessage === 3;

    if (error.status === RequestStatus.UN_AUTH) {
      deleteAuthTokenCookie();
      notifyErrorMessage = true;
      errorMessage = 'Invalid User: Authorization failed';
      window.location.pathname = ROUTES.LOGIN;
    }

    if (notifyErrorMessage) {
      addNotification(
        errorMessage,
        showWarnMessage ? NOTIFICATION_TYPES.WARNING : NOTIFICATION_TYPES.ERROR,
      );
    }

    throw ex;
  } finally {
    if (showLoader) {
      hideLoaderUseCase();
    }
  }
}

export default apiCall;
