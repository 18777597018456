import { capitalizeFirstLetter } from 'Common/Helpers/commonHelpers';
import React, {
  createContext, ReactNode, useContext, useEffect, useMemo, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { twoFactorAuthDetailsType } from '../Types';

interface AccountSettingsContextType {
  breadcrumb: breadCrumbTpe[];
  twoFactorAuthDetails: {
    code: string;
    error: boolean;
    backupCodes: string[];
    qrUrl: string;
    apiKey: string;
  };
  loading: boolean;
  setTwoFactorAuthDetails: React.Dispatch<React.SetStateAction<twoFactorAuthDetailsType>>;
  setBreadCrumb: React.Dispatch<React.SetStateAction<breadCrumbTpe[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccountSettingsContext = createContext<AccountSettingsContextType | null>(null);

interface AccountSettingsProviderProps {
  children: ReactNode;
}
interface breadCrumbTpe {
  label: string;
}

export function AccountSettingsProvider({ children }: AccountSettingsProviderProps) {
  const location = useLocation();

  const [breadcrumb, setBreadCrumb] = useState<breadCrumbTpe[]>([]);
  const [twoFactorAuthDetails, setTwoFactorAuthDetails] = useState<twoFactorAuthDetailsType>({
    code: '',
    error: false,
    backupCodes: [],
    qrUrl: '',
    apiKey: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const data = location?.pathname
      .split('/')
      .slice(2)
      .map((value) => ({ label: capitalizeFirstLetter(value) }));
    setBreadCrumb(data);
  }, [location?.pathname]);

  const value = useMemo(
    () => ({
      breadcrumb,
      twoFactorAuthDetails,
      loading,
      setTwoFactorAuthDetails,
      setBreadCrumb,
      setLoading,
    }),
    [breadcrumb, twoFactorAuthDetails, loading],
  );
  return (
    <AccountSettingsContext.Provider value={value}>{children}</AccountSettingsContext.Provider>
  );
}

export function useAccountSettings() {
  const context = useContext(AccountSettingsContext);
  if (!context) {
    throw new Error('useAccountSettings must be used within a AccountSettingsLayoutContainer');
  }
  return context;
}
