import React, { useState } from 'react';
import {
  NavLink, useLocation, Link,
} from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import Button from 'Common/Components/Button';
import CompanyIcon from 'Common/Components/Icons/CompanyIcon';
// import AcademyIcon from 'Common/Components/Icons/AcademyIcon';
import PartnerIcon from 'Common/Components/Icons/PartnerIcon';
import MyTeamIcon from 'Common/Components/Icons/MyTeamIcon';
import SettingsIcon from 'Common/Components/Icons/SettingsIcon';
import HelpIcon from 'Common/Components/Icons/HelpIcon';
import DevicesIcon from 'Common/Components/Icons/DevicesIcon';
import COLORS from 'Common/Helpers/colors';
import {
  COMPANIES_VIEW,
  DEBUG_DASHBOARD_VIEW,
  DEVICE_MANAGEMENT_VIEW,
  HELP_VIEW,
  // IS_INTERNAL_PARTNER,
  LOG_MANAGEMENT_PODLOGS_VIEW,
  LOG_MANAGEMENT_VIEW,
  MODEL_MANAGEMENT_VIEW_BROWSE,
  PARTNERS_VIEW,
  SETTINGS_VIEW,
  TEAM_VIEW,
} from 'Common/Constants/accesslistConstants';
import { authenticateTabs } from 'Common/Helpers/authByAccessList';
import LogManagementIcon from 'Common/Components/Icons/LogManagementIcon';
import { ROUTES } from '../../Constants/routes';
import {
  MenuItem, MenuType, SidebarI,
} from './Types';
import './style.scss';
import HelpSection from './HelpSection';

function getSidebarMenus({ toggleShowModal }: { toggleShowModal: () => void }) {
  const sidebarMenu: SidebarI = {
    top: [
      {
        id: 'company-nav-link',
        label: 'Companies',
        type: MenuType.LINK,
        icon: <CompanyIcon />,
        to: ROUTES.COMPANY,
        key: COMPANIES_VIEW,
      },
      {
        id: 'tools-nav-link',
        key: DEVICE_MANAGEMENT_VIEW,
        label: 'Tools',
        type: MenuType.PARENT_LINK,
        icon: <DevicesIcon />,
        to: ROUTES.TOOLS,
        subMenuItems: [
          {
            id: 'device-nav-link',
            label: 'Devices',
            type: MenuType.LINK,
            to: `${ROUTES.TOOLS}${ROUTES.DEVICE_MANAGEMENT}`,
            key: DEVICE_MANAGEMENT_VIEW,
          },
          {
            id: 'model-nav-link',
            label: 'Model Manager',
            type: MenuType.LINK,
            to: `${ROUTES.TOOLS}${ROUTES.MODELS}${ROUTES.MODEL_LIST}`,
            key: MODEL_MANAGEMENT_VIEW_BROWSE,
          },
          // {
          //   id: 'model-nav-link',
          //   label: 'Model Manager V2',
          //   type: MenuType.LINK,
          //   to: `${ROUTES.TOOLS}${ROUTES.MODELS_V2}${ROUTES.MODEL_LIST}`,
          //   key: MODEL_MANAGEMENT_VIEW_BROWSE,
          // },
          // {
          //   id: 'visual-detection-nav-link',
          //   label: 'Detection Visualizer',
          //   type: MenuType.LINK,
          //   to: ROUTES.VISUAL_DETECTION,
          //   key: IS_INTERNAL_PARTNER,
          //   target: '_blank',
          // },
          // {
          //   id: 'visual-reid-nav-link',
          //   label: 'ReID Visualizer',
          //   type: MenuType.LINK,
          //   to: ROUTES.VISUAL_REID,
          //   key: IS_INTERNAL_PARTNER,
          //   target: '_blank',
          // },
          // {
          //   id: 'synthetic-data-nav-link',
          //   label: 'Synthetic Data',
          //   type: MenuType.LINK,
          //   to: ROUTES.SYNTHETIC_DATA,
          //   key: IS_INTERNAL_PARTNER,
          //   target: '_blank',
          // },
          // {
          //   id: 'auto-annotator-nav-link',
          //   label: 'Auto Annotator',
          //   type: MenuType.LINK,
          //   to: ROUTES.AUTO_ANNOTATOR,
          //   key: IS_INTERNAL_PARTNER,
          //   target: '_blank',
          // },
        ],
      },
      {
        id: 'user-nav-link',
        label: 'Team',
        type: MenuType.LINK,
        icon: <MyTeamIcon />,
        to: ROUTES.USER,
        key: TEAM_VIEW,
      },
      {
        id: 'partner-nav-link',
        label: 'Partners',
        type: MenuType.LINK,
        icon: <PartnerIcon />,
        to: ROUTES.PARTNER,
        key: PARTNERS_VIEW,
      },
      {
        id: 'logs-nav-link',
        key: LOG_MANAGEMENT_VIEW,
        label: 'Logs',
        type: MenuType.PARENT_LINK,
        icon: <LogManagementIcon />,
        to: ROUTES.LOG_MANAGEMENT,
        subMenuItems: [
          {
            id: 'tasks-nav-link',
            label: 'Task Logs',
            type: MenuType.LINK,
            to: `${ROUTES.LOG_MANAGEMENT}`,
            key: LOG_MANAGEMENT_VIEW,
          },
          {
            id: 'pods-nav-link',
            label: 'Pod Status',
            type: MenuType.LINK,
            to: `${ROUTES.LOG_MANAGEMENT}${ROUTES.POD_STATUS}`,
            key: LOG_MANAGEMENT_PODLOGS_VIEW,
          },
          {
            id: 'debug-nav-link',
            label: 'Journey Debug',
            type: MenuType.LINK,
            to: ROUTES.DEBUG,
            key: DEBUG_DASHBOARD_VIEW,
          },
        ],
      },
      // {
      //   id: 'academy-nav-link',
      //   label: 'Academy',
      //   type: MenuType.LINK,
      //   icon: <AcademyIcon />,
      //   to: ROUTES.ACADEMY,
      // },
      {
        id: 'settings-nav-link',
        label: 'Settings',
        type: MenuType.LINK,
        icon: <SettingsIcon />,
        to: ROUTES.SETTINGS,
        key: SETTINGS_VIEW,
      },
    ],
    bottom: [
      {
        id: 'help-nav-link',
        label: 'Help',
        type: MenuType.BUTTON,
        icon: <HelpIcon color={COLORS.TEXT_OTHER} size={20} />,
        onClick: () => toggleShowModal(),
        key: HELP_VIEW,
      },
    ],
  };

  return sidebarMenu;
}

function SidebarMenu({ menu }: { menu: MenuItem }) {
  const { id, label, type, icon } = menu;
  const location = useLocation();
  let content = (
    <>
      <span className="sidebar-menu-icon">{icon}</span>
      <span className="sidebar-menu-label">{label}</span>
    </>
  );

  switch (type) {
    case MenuType.BUTTON: {
      const { onClick } = menu;
      content = (
        <Button
          id={id}
          className="sidebar-menu-item-btn-content"
          variant="text"
          type="button"
          onClick={onClick}
        >
          {content}
        </Button>
      );
      break;
    }
    case MenuType.LINK: {
      const { to } = menu;
      const isActive = location.pathname === to;
      const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (isActive) {
          event.preventDefault();
        }
      };
      content = (
        <NavLink id={id} className="sidebar-menu-item-content" to={to} onClick={handleClick}>
          {content}
        </NavLink>
      );
      break;
    }
    case MenuType.PARENT_LINK: {
      const { subMenuItems, to } = menu;
      const activeLink = location.pathname.includes(to);
      content = (
        <div className="menu-item">
          <Button
            id={id}
            className={`sidebar-menu-item-btn-content ${activeLink ? 'activeLink' : ''}`}
            variant="text"
            type="button"
          >
            {content}
          </Button>
          <div className="dropdown">
            <div className="arrow" />
            {subMenuItems?.map((sub) => (authenticateTabs(sub) ? (
              <Link
                key={sub.id}
                to={`${sub.to}${sub.to === location.pathname ? location.search : ''}`}
                target={sub.target || '_self'}
              >
                {sub.label}
              </Link>
            ) : null))}
          </div>
        </div>
      );
      break;
    }

    default:
      break;
  }

  return <li className="sidebar-menu-item">{content}</li>;
}

interface SidebarProps {
  className: string;
}

function Sidebar({ className }: SidebarProps) {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const toggleShowModal = () => setShowHelpModal(!showHelpModal);
  const { top, bottom } = getSidebarMenus({ toggleShowModal });

  return (
    <nav id="sidebar-fixed" className={`${className} wo-sidebar`}>
      <NavLink to={ROUTES.BASE} className="app-logo">
        <img className="logo-img" src="/assets/wobot-Icon-blue.svg" alt="" />
      </NavLink>
      <ul className="upper-menus">
        {top.map(
          (menu) => (authenticateTabs(menu) ? <SidebarMenu key={menu.id} menu={menu} /> : null),
        )}
      </ul>
      <OutsideClickHandler onOutsideClick={() => setShowHelpModal(false)}>
        <ul className="bottom-menus">
          {bottom.map((menu) => (
            <SidebarMenu key={menu.id} menu={menu} />
          ))}
        </ul>
        {showHelpModal && <HelpSection />}
      </OutsideClickHandler>
    </nav>
  );
}

export default Sidebar;
