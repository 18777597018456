import React, { ReactNode, useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';

import styles from './StyleSidebarLink.module.scss';

interface SidebarItemType {
  name: string;
  link: string;
  icon: ReactNode;
}

interface SidebarLinksProps {
  handleRouteChange: Function;
  sideBarItems: SidebarItemType[];
}

function SidebarLinks({ handleRouteChange, sideBarItems }: SidebarLinksProps) {
  const { pathname } = useLocation();

  const isCurrentLink = useCallback((item: SidebarItemType) => item.link === pathname, [pathname]);
  // const isAuthenticated = (role) => {
  //   if (!role) return true; //Every User has access
  //   if (Array.isArray(role)) {
  //     return role.every((r) => Roles.authenticateViewAndAction(r));
  //   }
  //   return Roles.authenticateViewAndAction(role);
  // };
  const renderSettingMenus = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    sideBarItems?.map((item) => {
      // if (isAuthenticated(item.role)) {
      return (
        <li key={item.name} className={isCurrentLink(item) ? `${styles.link_disabled}` : ''}>
          <NavLink
            className={({ isActive }) => (isActive ? `${styles.active_List}` : '')}
            to={item.link}
            onClick={() => handleRouteChange(item)}
          >
            {item.icon}
            {item.name}
          </NavLink>
        </li>
      );
      // }

      return null;
    });

  return (
    <div className={styles.sidebar_Block}>
      <ul className="mb-0">{renderSettingMenus()}</ul>
    </div>
  );
}
SidebarLinks.propTypes = {
  handleRouteChange: PropTypes.func.isRequired,
};
export default SidebarLinks;
