import React from 'react';
// eslint-disable-next-line object-curly-newline
import { Navigate, Route, Routes } from 'react-router-dom';

import PageNotFound from 'Common/Pages/PageNotFound';
import { ROUTES } from '../../Constants/routes';
import { AccountSettingsProvider } from './Contexts/AccountSettingsLayoutContext';
import AccountSettingsLayout from './Components/Common/AccountSettingsLayout';
import TwoFactorAuthBackupCodesContainer from './Containers/TwoFactorAuthBackupCodes';
import AccountProfileContainer from './Containers/AccountProfileContainer';
import AccountSecurityContainer from './Containers/AccountSecurityContainer';

function AccountSettings() {
  return (
    <AccountSettingsProvider>
      <AccountSettingsLayout>
        <Routes>
          <Route path="/" element={<Navigate to={ROUTES.ACCOUNT_PROFILE_ROUTE} replace />} />
          <Route path={ROUTES.ACCOUNT_PROFILE} element={<AccountProfileContainer />} />
          <Route path={ROUTES.ACCOUNT_SECURITY} element={<AccountSecurityContainer />} />
          {/* eslint-disable-next-line max-len */}
          <Route
            path={`${ROUTES.ACCOUNT_SECURITY}/${ROUTES.TWO_FACTOR_AUTH_BACKUP_CODES}`}
            element={<TwoFactorAuthBackupCodesContainer />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </AccountSettingsLayout>
    </AccountSettingsProvider>
  );
}
export default AccountSettings;
