import React, {
  ReactNode, useCallback, useMemo,
} from 'react';
import SidebarLinks from 'Common/Components/SidebarLinks';
import styles from './Style.module.scss';
import { useAccountSettings } from '../../../Contexts/AccountSettingsLayoutContext';
import { getSideBarItems, PAGE_CONFIG } from '../../../Utils';
import PageLayout from '../../../../../Components/PageLayout';
import { getPageTitle } from '../../../../../Constants/routes';

interface AccountSettingsLayoutProps {
  children: ReactNode;
}

function AccountSettingsLayout({ children }: AccountSettingsLayoutProps) {
  const { breadcrumb, setBreadCrumb } = useAccountSettings();
  const sideBarItems = useMemo(getSideBarItems, []);
  const { title, tagline, metaTitle, metaDescription } = PAGE_CONFIG;
  const updateBreadcrumb = useCallback(
    (item: any) => {
      setBreadCrumb([{ label: item.name }]);
    },
    [setBreadCrumb],
  );
  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper crumb-cursor-type">
        <PageLayout
          title={title}
          tagline={tagline}
          pageMetaTitle={getPageTitle(metaTitle)}
          pageMetaDescription={metaDescription}
          breadcrumbs={[{ label: 'Account Settings' }, ...breadcrumb]}
        >
          <div className={styles.body_container}>
            <div className={styles.content_sidebar}>
              <SidebarLinks handleRouteChange={updateBreadcrumb} sideBarItems={sideBarItems} />
            </div>
            <div className={styles.body_content}>
              <div className="panel-body">{children}</div>
            </div>
          </div>
        </PageLayout>
      </div>
    </div>
  );
}

export default AccountSettingsLayout;
