import React, { lazy } from 'react';
import LazyRoute from 'Common/Components/LazyRoute';

const TwoFactorAuthBackupCodes = lazy(
  () => import('../../Components/TwoFactorAuthBackupCodesCompnent'),
);
function TwoFactorAuthBackupCodesContainer() {
  return <LazyRoute lazy={TwoFactorAuthBackupCodes} />;
}

export default TwoFactorAuthBackupCodesContainer;
