import * as React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './breadcrumb.module.scss';
import ArrowDownIcon from '../Icons/ArrowDownIcon';

interface BreadcrumbLink {
  label: string;
  to?: string;
}

export interface BreadcrumbsProps {
  links: BreadcrumbLink[];
  className?: string;
}

function Breadcrumbs({ links, className }: BreadcrumbsProps) {
  const isLast = (index: number) => links.length - 1 === index;

  const renderBreadcrumbLink = ({ label, to }: BreadcrumbLink, i: number) => {
    if (!isLast(i)) {
      if (to) {
        return (
          <span className={styles.label} key={i}>
            <NavLink className={styles.link} to={to}>
              {label}
            </NavLink>
            <ArrowDownIcon className={styles.arrow} height={5} />
          </span>
        );
      }
      return (
        <span className={styles.label} key={i}>
          <span className={styles.notLinkLable}>{label}</span>
          <ArrowDownIcon className={styles.arrow} height={5} />
        </span>
      );
    }
    return <span className={styles.label}>{label}</span>;
  };

  return (
    <div className={`${styles.breadcrumbWrapper} ${className}`}>
      {links.map(renderBreadcrumbLink)}
    </div>
  );
}

Breadcrumbs.defaultProps = {
  className: '',
};

export default Breadcrumbs;
